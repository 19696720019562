<template>
  <div class="h-screen p-5">
    <div class="my-8 flex justify-between items-center">
      <h1 class="font-semibold text-xl text-white">Compare by assessment</h1>
      <select
        v-if="!isLoading"
        v-model="selectedAssessment"
        class="bg-white p-1"
      >
        <option v-if="assessmentsKeys.length === 0" disabled value="">
          {{ "No assessments" }}
        </option>
        <option
          v-for="assessment in assessmentsKeys"
          :key="assessment"
          :value="assessment"
        >
          {{ assessment }}
        </option>
      </select>
    </div>
    <LocationsTable
      v-if="!isLoading"
      :labels="['Users', 'Track', 'Task Switch', 'Figure Decide', 'Total']"
      :bottomArrows="false"
    >
      <template v-slot:table-content>
        <tr
          v-for="(user, id) in assessments[selectedAssessment]"
          :key="id"
          class="bg-white p-5"
        >
          <td class="text-center">{{ user.name }}</td>
          <td class="text-center">{{ user.score["Track"] }}</td>
          <td class="text-center">{{ user.score["Task Switch"] }}</td>
          <td class="text-center">{{ user.score["Figure Decide"] }}</td>
          <td class="text-center">{{ user.score["total"] }}</td>
        </tr>
      </template>
    </LocationsTable>
  </div>
</template>

<script setup>
import { ref, onMounted, computed } from "vue";
import { useStore } from "vuex";

import { getUserAssessmentScores } from "@/services/user/user.js";
import LocationsTable from "@/components/location/LocationsTable.vue";

const store = useStore();

const user = computed(() => store.getters["user/getUserData"]);

const rawData = ref([]);
const selectedAssessment = ref("");
const isLoading = ref(true);

const assessments = computed(() => {
  const result = {};
  if (rawData.value.length > 0) {
    const userTitles = rawData.value[0].slice(1).map((user) => user.title);
    const userIds = rawData.value[0].slice(1).map((user) => user.id);
    rawData.value.forEach((row, index) => {
      if (index === 0) {
        return;
      }
      const assessmentName = row.shift();

      row.forEach((score, userIndex) => {
        const userId = userIds[userIndex];
        const userName = userTitles[userIndex];

        if (!result[assessmentName]) {
          result[assessmentName] = {};
        }

        if (!result[assessmentName][userId]) {
          result[assessmentName][userId] = {
            name: userName,
            score: score,
          };
        }
      });
    });
  }
  return result;
});

const assessmentsKeys = computed(() => Object.keys(assessments.value));

onMounted(async () => {
  await userAssessmentScores();
});

async function userAssessmentScores() {
  isLoading.value = true;
  const data = await getUserAssessmentScores(user.value.location, "Oculus");
  rawData.value = data;
  const keys = assessmentsKeys.value;
  if (keys.length > 0) {
    selectedAssessment.value = keys[0];
  }
  isLoading.value = false;
}
</script>

<style>
table {
  border: none;
}

th {
  color: #1d2e48;
  background: #ecf0f5;
}

tr {
  border-top: 1px solid #ecf0f5;
  border-bottom: 2px solid #ecf0f5;
}

th,
td {
  border: none;
}

tr td {
  padding: 20px;
}

tr:hover td {
  background: #4e5066;
  color: #ffffff;
}

tr:first-child {
  border-top: none;
}

tr:last-child {
  border-bottom: none;
}
</style>
